import { Bundle, BundleLink } from "../types/fhir";

export const getNextUrl = (bundle: Bundle): string | undefined => {
  const link = bundle?.link?.find((link: BundleLink) => {
    return link.relation === "next";
  })?.url;

  if (!link) {
    return undefined;
  }

  //Responses looks like 'http://localhost:8082/fhir?_getpages='
  //Look for '/fhir', and prepend it again.
  return "/fhir" + link?.split("/fhir")[1];
};
