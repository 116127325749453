import { Bundle, BundleEntry, ValueSet } from "../types/fhir";
import { apiClientService } from "./apiClientService";
import { getNextUrl } from "./paging";
import { isNaN } from "mathjs";
import { IExtentionType } from "../types/IQuestionnareItemType";

const apiClient = apiClientService.axiosClient;
const notRetired = "status:not=retired";

const addCanBeCalculatedExtension = (valueSets: Set<ValueSet>) => {
  valueSets.forEach((valueSet) => {
      //Does the valueset contain any include where all codes are numeric?
      if (valueSet.compose?.include?.length ?? 0 > 0) {
        const isNumericCodes = valueSet.compose?.include.some(include => include.concept?.every(concept => isNumericOrUN(concept.code)))
        valueSet.extension = [
          ...(valueSet.extension ?? []).filter((extension) => extension.url !== IExtentionType.canBeCalculated),
          {
            url: IExtentionType.canBeCalculated,
            valueBoolean: isNumericCodes
          }
        ]
      }
    }
  );
}

const getAll = async (): Promise<ValueSet[] | undefined> => {
    let response = await apiClient.get<Bundle>(`/fhir/ValueSet?${notRetired}&_count=500`);

    const valueSets = new Set<ValueSet>();

    mapBundleToValueSets(response.data)?.forEach((valueSet) => {
      valueSets.add(valueSet);
    });

    while (getNextUrl(response.data)) {
      const nextUrl = getNextUrl(response.data);
      if (!nextUrl) break;

      response = await apiClient.get<Bundle>(nextUrl);
      mapBundleToValueSets(response.data)?.forEach((valueSet) => {
        valueSets.add(valueSet);
      });
    }
    addCanBeCalculatedExtension(valueSets);
    return Array.from(valueSets);
  }
;

const isNumericOrUN = (num: unknown) => {
  if (num === 'UN') {
    return true;
  }
  try {
    return (typeof (num) === 'number' || typeof (num) === "string" && num.trim() !== '') && !isNaN(num as number);
  } catch (e) {
    return false;
  }
};


const getByUrl = async (url: string): Promise<ValueSet[] | undefined> => {
  const response = await apiClient.get<Bundle>(`/fhir/ValueSet?url=${url}&${notRetired}`);
  const valueSets = mapBundleToValueSets(response.data);
  addCanBeCalculatedExtension(new Set(valueSets ?? []));
  return valueSets;
};

const getByName = async (name: string): Promise<ValueSet[] | undefined> => {
  const response = await apiClient.get<Bundle>(`/fhir/ValueSet?name=${name}&${notRetired}`);
  const valueSets = mapBundleToValueSets(response.data);
  addCanBeCalculatedExtension(new Set(valueSets ?? []));
  return valueSets;
};

const valuesetService = {getAll, getByUrl, getByName};

export default valuesetService;

const mapBundleToValueSets = (bundle: Bundle): ValueSet[] | undefined => {
  return bundle?.entry?.map((entry: BundleEntry) => {
    return JSON.parse(JSON.stringify(entry.resource));
  });
};
