import axios from "axios";
import { keycloakService } from "./keycloakService";

const _axios = axios.create({
    baseURL: window.__RUNTIME_CONFIG__.REACT_APP_FHIR_SERVER_URL,
    headers: {
        "Content-Type": "application/json",
    },
});

const apiGateway = axios.create({
    baseURL: window.__RUNTIME_CONFIG__.REACT_APP_APIGATEWAY_URL,
    headers: {
        "Content-Type": "application/json",
    }
});

const axiosClient = _axios;

const configure = () => {
    _axios.defaults.validateStatus = function () {
        return true;
    };
    _axios.interceptors.request.use((config) => {
        if (keycloakService.isLoggedIn()) {
            const cb = () => {
                if (!config.headers) {
                    console.error("HTTP headers are somehow undefined");
                    return Promise.reject();
                }
                config.headers.Authorization = `Bearer ${keycloakService.getToken()}`;
                //Disable cache when accessing HAPI
                config.headers["Cache-Control"] = "no-cache";
                return Promise.resolve(config);
            };
            return keycloakService.updateToken(cb);
        }
    });

    apiGateway.defaults.validateStatus = function () {
        return true;
    };

    apiGateway.interceptors.request.use((config) => {
        if (keycloakService.isLoggedIn()) {
            const cb = () => {
                if (!config.headers) {
                    console.error("HTTP headers are somehow undefined");
                    return Promise.reject();
                }
                config.headers.Authorization = `Bearer ${keycloakService.getToken()}`;
                return Promise.resolve(config);
            };
            return keycloakService.updateToken(cb);
        }
    });

};

export const apiClientService = {
    axiosClient: axiosClient,
    apiGateway: apiGateway,
    configure,
};
