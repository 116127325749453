import React, { useEffect } from "react";
import { Button, List, Text } from "@mantine/core";
import "./MediaTableItem.css";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import questionnaireService from "../../connection/questionnaireService";
import { Questionnaire } from "../../types/fhir";

export type MediaTableItemProps = {
  id: string,
  text: string,
  width: number,
  height: number,
  //base64 encoded image string
  content: string,

  hash: string,

  //Delete function
  onDelete: () => void,
};

const UsageList = (props: { questionnaires: Questionnaire[] }) => {
  const {questionnaires} = props;
  return (<List spacing="xs"
                size="sm"
                listStyleType={"none"}
  >
    {
      questionnaires.map((questionnaire: Questionnaire) => {
        return <List.Item key={questionnaire.id}>{questionnaire.id}:{questionnaire.name}</List.Item>
      })
    }
  </List>);
}

export const MediaTableItem = (props: MediaTableItemProps): JSX.Element => {
  const {id, text, content, hash, onDelete} = props;
  const {t} = useTranslation();
  const [deleteClicked, setDeleteClicked] = React.useState<boolean>(false);

  //this query is used to ge all usages of the image from the server
  const {
    data,
    isLoading,
    refetch,
  } = useQuery<Questionnaire[] | undefined, Error>('questionnairesUsingMediaWithId-' + id, async() => {
    return await questionnaireService.getQuestionnairesUsingMedia(id);
  }, {enabled: false});

  useEffect(() => {
    if(deleteClicked) {
      refetch();
    }
  }, [deleteClicked])

  return (
      <tr key={id}>
        <td>{<Text>{id}</Text>}</td>
        <td><img className="grow" width={128} src={"data:image/webp;base64," + content}></img></td>
        <td>
          <div dangerouslySetInnerHTML={{__html: text}}/>
        </td>
        <td>{<Text>{hash}</Text>}</td>
        {<td><Button color={deleteClicked ? "red" : "primary"} disabled={isLoading}
                     onClick={() => deleteClicked ? onDelete() : setDeleteClicked(true)}>
          {deleteClicked ? t("Confirm") : t("Remove")}
        </Button></td>}

        <td>
          {!isLoading && !data && <Button onClick={() => refetch()}>{t("Download")}</Button>}
          {isLoading && <Text>{t("Loading")}...</Text>}
          {data && data.length > 0 && (
              <UsageList questionnaires={data}/>
          )}
        </td>
      </tr>
  );
};
