import React from "react";
import { Text } from "@mantine/core";
import { SOR } from "../../connection/sorService";

export type SORTableItemProps = {
  sor: SOR;
};

export const SORTableItem = (props: SORTableItemProps): JSX.Element => {
  const sor = props.sor;

  return (
    <tr key={sor.sor}>
      <td>{<Text>{sor.unitName}</Text>}</td>
      <td>{<Text>{sor.sor}</Text>}</td>
    </tr>
  );
};
