import { useState, useEffect } from "react";
import sorService, { SOR } from "../connection/sorService";

export const SorSearcher = () => {

  const [searchTerm, setSearchTerm] = useState("");
  const [sorList, setSorList] = useState<SOR[]>([]);

  const [selectedSors, setSelectedSors] = useState<string[]>([]);


    const getSearchTerm = (searchTerm: string, selectedSors: string[]) => {

        return {
            search: searchTerm,
            selected: selectedSors
        }

    };

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            const fetch = async () => {
                try {
                    const newSearch = getSearchTerm(searchTerm, selectedSors);
                    if (newSearch.selected.length > 0 || newSearch.search !== "") {
                        const res = await sorService.search(newSearch.search, newSearch.selected);
                        if (res !== undefined) {
                            setSorList(res);
                        }
                    }
                } catch (err) {
                    console.error(err);
                }
            };
            fetch();
        }, 150);
        return () => clearTimeout(timeoutId);
    }, [searchTerm, selectedSors]);

    return {sorList, setSearchTerm, searchTerm, setSelectedSors};
};
