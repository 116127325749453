import { AxiosRequestConfig } from "axios";
import Keycloak from "keycloak-js";

const realm = window.__RUNTIME_CONFIG__.REACT_APP_KEYCLOAK_REALM_NAME;
const clientId = window.__RUNTIME_CONFIG__.REACT_APP_KEYCLOAK_CLIENTID;

const keycloak = new Keycloak({
  url: window.__RUNTIME_CONFIG__.REACT_APP_KEYCLOAK_URL,
  realm: realm,
  clientId: clientId,
});

keycloak.onTokenExpired = async () => {
  //Refresh token when previous expires
  await keycloak.updateToken(60 * 2).catch(keycloak.login);
};

const login = (onAuthenticatedCallback: () => void, onFailedToAuthenticateCallback: () => void): void => {
  keycloak
    .init({ onLoad: "login-required", checkLoginIframe: false })
    .then((authenticated: boolean) => {
      if (!authenticated) {
        return onFailedToAuthenticateCallback();
      }
      const isBuilder = keycloak.realmAccess?.roles.includes("questionnaire-creator");
      if (!isBuilder) {
        return onFailedToAuthenticateCallback();
      }

      return onAuthenticatedCallback();
    })
    .catch((e) => {
      onFailedToAuthenticateCallback();
      console.error(`keycloak init exception: ${e}`);
    });
};

const getToken = () => keycloak.token;

const manualLogin = () => keycloak.login();

const isLoggedIn = () => !!keycloak.token;

const updateToken = (successCallback: () => Promise<AxiosRequestConfig>) =>
  keycloak.updateToken(120).then(successCallback).catch(keycloak.login);

export const keycloakService = {
  login,
  manualLogin,
  keycloak,
  getToken,
  isLoggedIn,
  updateToken,
};
