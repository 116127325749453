import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./router/index";
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClientProvider } from "react-query";
import queryClient from "./connection/queryClient";
import { MantineProvider, useMantineTheme } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import {FeatureFlagsProvider } from "./featureFlag";
import { ModalsProvider } from "@mantine/modals";

function QuestionnairesApp(): JSX.Element {
  return (
    <Router>
      <Routes />
    </Router>
  );
}

function App(): JSX.Element {
  const theme = useMantineTheme();
  theme.colorScheme = "light";
  return (

      <MantineProvider
        withNormalizeCSS
        withGlobalStyles
        theme={theme}
      >
        <ModalsProvider>
        <Notifications position="top-right" />
        <QueryClientProvider client={queryClient}>
          <FeatureFlagsProvider>
          <QuestionnairesApp />
          </FeatureFlagsProvider>
          <ReactQueryDevtools position="bottom-right" />
        </QueryClientProvider>
        </ModalsProvider>
      </MantineProvider>

  );
}

export default App;
