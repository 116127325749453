import React, { useState } from "react";
import { Button, Grid, Group, Table, Text, TextInput } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { SOR } from "../connection/sorService";
import { SORTableItem } from "../components/SORTableItem/SORTableItem";
import { SorSearcher } from "../helpers/sorSearcher";

export const SORListPage = (): JSX.Element => {
  const { t } = useTranslation();

  const [page, setPage] = useState(0);
  const { sorList, setSearchTerm, searchTerm } = SorSearcher();

  const resultsPerPage = 20;

  return (
    <>
      <Grid>
        <Grid.Col span={6}>
          <TextInput
            type="text"
            placeholder={t("Search")}
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.currentTarget.value);
            }}
          />
        </Grid.Col>
        <Group>
          <Button onClick={() => setPage(Math.round(Math.max(page - 1, 0)))}>{t("Previous Page")}</Button>
          <Text>
            Side {page + 1} af {Math.ceil((sorList?.length || 0) / resultsPerPage)}
          </Text>
          <Button onClick={() => setPage(Math.round(Math.min((sorList?.length || 0) / resultsPerPage, page + 1)))}>
            {t("Next Page")}
          </Button>
        </Group>
      </Grid>
      <Table>
        <thead>
          <tr>
            <th>
              <Text>{t("Name")}</Text>
            </th>
            <th>
              <Text>{t("SOR")}</Text>
            </th>
          </tr>
        </thead>
        <tbody>

          {sorList && sorList.length > 0 && sorList.slice(page * resultsPerPage, page * resultsPerPage + resultsPerPage).map((sor: SOR) => {
            return (
              <SORTableItem
                sor={sor}
                key={sor.sor}
              />
            );
          })}
        </tbody>
      </Table>
    </>
  );
};
