import React from "react";

export const FeatureFlags = React.createContext<FeatureContext>({} as FeatureContext);

export interface FeatureConfig {
  [key: string]: boolean;
}

const isFeatureEnabled =(featureName: string, features: FeatureConfig | undefined): boolean => {
  return features?.[featureName] ?? false;
}

const isHelpImagesEnabled = (features: FeatureConfig | undefined): boolean => {
  return isFeatureEnabled("questionnaire-item-help-image", features)
}

export type FeatureContext = {
  features: FeatureConfig | undefined
  isFeatureEnabled: (featureName: string) => boolean
  isHelpImagesEnabled: () => boolean
}

export const FeatureFlagsProvider = (props: { children: any }) => {
  const {children} = props;
  const [features, setFeatures] = React.useState<FeatureConfig>();

  React.useEffect(() => {
    fetch("feature.config.json", {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("feature.config", data);
        setFeatures(data);
      });
  }, [])

  return (
    <FeatureFlags.Provider value={{
      features: features,
      isFeatureEnabled: featureName => isFeatureEnabled(featureName, features),
      isHelpImagesEnabled: () => isHelpImagesEnabled(features)
    }}>
      {children}
    </FeatureFlags.Provider>
  );


};
