import React from "react";
import { useQuery } from "react-query";
import valuesetService from "../connection/valuesetService";
import { ValueSet } from "../types/fhir";
import { ValuesetTableItem } from "../components/ValuesetTableItem/ValuesetTableItem";
import { Table, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Loader } from '@mantine/core';


export const ValuesetListPage = (): JSX.Element => {
  const { data, isLoading } = useQuery<ValueSet[] | undefined, Error>("valuesets", async () => {
    return await valuesetService.getAll();
  });
  const { t } = useTranslation();

  return (
    <>
      {isLoading && (
        <Loader  variant={"dots"} />
      )}

      {!isLoading && data?.length === 0 && (
        <Text>{t("No results")}</Text>
      )}

      {!isLoading && data?.length !== 0 && (
        <Table>
          <thead>
          <tr>
            <th>
              <Text>{t("Name")}</Text>
            </th>
            <th>
              <Text>{t("Description")}</Text>
            </th>
            <th>
              <Text>{t("IG URL")}</Text>
            </th>
            <th>
              <Text>{t("Details")}</Text>
            </th>
          </tr>
          </thead>
          <tbody>
          {data?.map((valueset: ValueSet) => {
            return (
              <ValuesetTableItem
                {...valueset}
                key={valueset.id}
              />
            );
          })}
          </tbody>
        </Table>
      )}

    </>
  );
};
