import React from "react";
import { ValueSet } from "../../types/fhir";
import { Text, Button, Modal, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";

export const ValuesetTableItem = (valueset: ValueSet): JSX.Element => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = React.useState(false);
  return (
    <>
      <tr key={valueset.id}>
        <td>{<Text>{valueset.name}</Text>}</td>
        <td>{<Text>{valueset.description}</Text>}</td>
        <td>
          {
            <Text
              component="a"
              variant="link"
              href={valueset.url}
            >
              {valueset.url}
            </Text>
          }
        </td>
        <td>
          <Button
            onClick={() => {
              setShowModal(true);
            }}
          >
            {t("view")}
          </Button>
        </td>
      </tr>
      <Modal
        onClose={() => {
          setShowModal(false);
        }}
        opened={showModal}
      >
        <Title>{valueset.name}</Title>
        <div dangerouslySetInnerHTML={{ __html: valueset.text?.div as string }}></div>
      </Modal>
    </>
  );
};
