import React from "react";
import { Questionnaire, QuestionnaireItem, Coding } from "../../types/fhir";
import { Text, Button, Modal, Paper, Box, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import "./QuestionnaireTableItem.css"
import { getUuidFromQuestionnaire, hasUuid } from "../../helpers/uuidHelper";

export type QuestionnaireTableItemProps = {
  questionnaire: Questionnaire;
  onEditClicked: () => void;
};

export const QuestionnaireTableItem = (props: QuestionnaireTableItemProps): JSX.Element => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = React.useState(false);
  const questionnaire = props.questionnaire;
  const onEditClicked = props.onEditClicked;

  return (
    <>
      <tr key={questionnaire.id}>
        <td>{<Text className="columnId">{questionnaire.id}</Text>}</td>
        <td>{<Text className="columnStatus">{questionnaire.status}</Text>}</td>
        <td>{<Text className="columnName">{questionnaire.title}</Text>}</td>
        <td>{<Text className="columnDescription">{questionnaire.description}</Text>}</td>
        <td>{<Text className="columnUuid">{hasUuid(questionnaire) ? getUuidFromQuestionnaire(questionnaire) : ""}</Text>}</td>
        <td>
          <Button
            onClick={() => {
              onEditClicked?.();
            }}
          >
            {t("Edit")}
          </Button>
        </td>
      </tr>
      <Modal
        onClose={() => {
          setShowModal(false);
        }}
        opened={showModal}
      >
        <Title>{questionnaire.title}</Title>
        <div>
          {questionnaire.item?.map((item: QuestionnaireItem, index: number) => {
            return (
              <QuestionnaireItemDisplay
                key={index}
                {...item}
              />
            );
          })}
        </div>
      </Modal>
    </>
  );
};

const QuestionnaireItemDisplay = (item: QuestionnaireItem): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Paper
      withBorder
      shadow="xs"
      p="md"
      key={item.id}
    >
      <Text>
        {t("Text")}: {item.text}
      </Text>
      {item.code?.map((code: Coding, index: number) => {
        return (
          <Box key={index}>
            <Text>
              {t("Display")}: {code.display}
            </Text>
            <Text>
              {t("System")}: {code.system}
            </Text>
            <Text>
              {t("System")}: {code.extension}
            </Text>
          </Box>
        );
      })}
      <Text>
        {t("Definition")}: {item.definition}
      </Text>
    </Paper>
  );
};
