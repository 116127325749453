import { TreeState } from "../store/treeStore/treeStore";
import { IExtentionType } from "../types/IQuestionnareItemType";
import { Questionnaire } from "../types/fhir";

export const getUuidFromQuestionnaire = (questionnaire: Questionnaire): string => {
  return (
    questionnaire?.extension?.find((extension) => extension.url === IExtentionType.uuid)?.valueUuid ??
    `urn:uuid:${crypto.randomUUID()}`
  );
};

export const hasUuid = (questionnaire: Questionnaire): boolean => {
  return questionnaire?.extension?.some((extension) => extension.url === IExtentionType.uuid) ?? false;
};

export const getUuidFromState = (state: TreeState): string => {
  return state?.qMetadata?.uuid ?? `urn:uuid:${crypto.randomUUID()}`;
};
