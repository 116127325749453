import { QueryClient } from "react-query";

const queryClient = new QueryClient();
queryClient.setDefaultOptions({
  queries: {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  },
});

export default queryClient;
