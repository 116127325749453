import React, { useEffect } from "react";
import { useQuery } from "react-query";
import questionnaireService from "../connection/questionnaireService";
import { Questionnaire } from "../types/fhir";
import { QuestionnaireTableItem } from "../components/QuestionnaireTableItem/QuestionnaireTableItem";
import { Loader, Table, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { dynamicSortMultiple } from "../helpers/sortHelper";
import { showNotification } from "@mantine/notifications";
import { getUuidFromQuestionnaire, hasUuid } from "../helpers/uuidHelper";

export const QuestionnaireListPage = (): JSX.Element => {
  const navigate = useNavigate();

  const { data, isLoading } = useQuery<Questionnaire[] | undefined, Error>("questionnaires", async () => {
    return await questionnaireService.getAllForOverview();
  });


  useEffect(() => {
    if(data){
      //Group questionnaires by title
      const questionnairesByTitle = data.reduce((acc, questionnaire) => {
        acc[questionnaire.title!] = acc[questionnaire.title!] || [];
        acc[questionnaire.title!].push(questionnaire);
        return acc;
      }, Object.create(null));
      //If any of the titles have more than one questionnaire, show the ids of the questionnaires
      for (const title in questionnairesByTitle) {
        if (questionnairesByTitle[title].length > 1) {
          console.warn(`Title: ${title} has more than one questionnaire with the same title: ${(questionnairesByTitle[title] as Questionnaire[]).map(q  => q.id)}`);
          showNotification({
            title: t("Warning (Added to console)"),
            message: t("Title: {{title}} has more than one questionnaire with the same title: {{questionnaireIds}}", {title: title, questionnaireIds: (questionnairesByTitle[title] as Questionnaire[]).map(q  => q.id).join(", ")}),
            color: "orange",
            autoClose: 10000,
          })
        }
      }
      //Group questionnaires by uuid
      const questionnairesByUuid = data.reduce((acc, questionnaire) => {
        if (hasUuid(questionnaire)){
          const uuid = getUuidFromQuestionnaire(questionnaire);
          acc[uuid] = acc[uuid] || [];
          acc[uuid].push(questionnaire);
        }
        return acc;
      }, Object.create(null));
      //If any of the uuids have more than one questionnaire, show the ids of the questionnaires
      for (const uuid in questionnairesByUuid) {
        if (questionnairesByUuid[uuid].length > 1) {
          console.warn(`UUID: ${uuid} has more than one questionnaire with the same UUID: ${(questionnairesByUuid[uuid] as Questionnaire[]).map(q  => q.id)}`);
          showNotification({
            title: t("Warning (Added to console)"),
            message: t("UUID: {{uuid}} has more than one questionnaire with the same UUID: {{questionnaireIds}}", {uuid: uuid, questionnaireIds: (questionnairesByUuid[uuid] as Questionnaire[]).map(q  => q.id).join(", ")}),
            color: "orange",
            autoClose: 10000,
          })
        }
      }
    }
  }, [data]);

  //Check if there are any questionnaires with same UUID


  const { t } = useTranslation();

  function QuestionnaireEditClicked(questionnaire: Questionnaire) {
    navigate("/formbuilder", { state: { questionnaireId: questionnaire.id } });
  }

  if (isLoading) {
    return <Loader variant={"dots"} />;
  }

  if (!isLoading && data?.length === 0) {
    return <Text>Ingen resultater</Text>;
  }

  return (
    <Table>
      <thead>
        <tr>
          <th>
            <Text>{t("ID")}</Text>
          </th>
          <th>
            <Text>{t("Status")}</Text>
          </th>
          <th>
            <Text>{t("Name")}</Text>
          </th>
          <th>
            <Text>{t("Description")}</Text>
          </th>
          <th>
            <Text>{t("UUID")}</Text>
          </th>
          <th>
            <Text>{t("Edit")}</Text>
          </th>
        </tr>
      </thead>
      <tbody>
        {data?.sort(dynamicSortMultiple("status", "title"))?.map((questionnaire: Questionnaire) => {
          return (
            <QuestionnaireTableItem
              questionnaire={questionnaire}
              key={questionnaire.id}
              onEditClicked={() => {
                QuestionnaireEditClicked(questionnaire);
              }}
            />
          );
        })}
      </tbody>
    </Table>
  );
};
