import React, { useContext, useState } from "react";
import { Route, Routes as Rts, Link } from "react-router-dom";
import { TreeContextProvider } from "../store/treeStore/treeStore";
import { AppShell, Header, Title, Navbar, useMantineTheme, MediaQuery, Burger, Button, Loader } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { ValuesetListPage } from "../views/ValuesetListPage";
import "./index.css";
import { QuestionnaireListPage } from "../views/QuestionnaireListPage";
import { SORListPage } from "../views/SORListPage";
import { MediaListPage } from "../views/MediaListPage";
import { FeatureFlags } from "../featureFlag";

export default function Routes(): JSX.Element {
  const [opened, setOpened] = useState(false);

  const {isHelpImagesEnabled} = useContext(FeatureFlags);

  const {t} = useTranslation();

  const FormBuilder = React.lazy(() => import("../views/FormBuilder"));

  const theme = useMantineTheme();
  return (
    <AppShell
      navbarOffsetBreakpoint="sm"
      fixed
      header={
        <Header height={60}>
          <div style={{display: "flex", justifyContent: "space-between"}}>
            <MediaQuery
              largerThan="sm"
              styles={{display: "none"}}
            >
              <Burger
                opened={opened}
                onClick={() => setOpened((o) => !o)}
                size="sm"
                color={theme.colors.gray[6]}
                mr="xl"
              />
            </MediaQuery>
            <Title order={1}>{t("Form builder")}</Title>
          </div>
        </Header>
      }
      navbar={
        <Navbar
          width={{sm: 250}}
          hiddenBreakpoint="sm"
          hidden={!opened}
        >
          <Navbar.Section
            grow
            mt="lg"
          >
            <div style={{display: "flex", flexDirection: "column"}}>
              <Button
                fullWidth
                size="lg"
                variant="subtle"
                component={Link}
                to="/"
              >
                {t("Home")}
              </Button>
            </div>
            <div style={{display: "flex", flexDirection: "column"}}>
              <Button
                fullWidth
                size="lg"
                variant="subtle"
                component={Link}
                to="/formbuilder"
                data-cy="menu-formbuilder"
              >
                {t("Questionnaire Creator")}
              </Button>
            </div>
            <div style={{display: "flex", flexDirection: "column"}}>
              <Button
                fullWidth
                size="lg"
                variant="subtle"
                component={Link}
                to="/valuesets"
                data-cy="menu-valuesets"
              >
                {t("Valuesets")}
              </Button>
            </div>
            <div style={{display: "flex", flexDirection: "column"}}>
              <Button
                fullWidth
                size="lg"
                variant="subtle"
                component={Link}
                to="/questionnaires"
                data-cy="menu-questionnaires"
              >
                {t("Questionnaires")}
              </Button>
            </div>
            <div style={{display: "flex", flexDirection: "column"}}>
              <Button
                fullWidth
                size="lg"
                variant="subtle"
                component={Link}
                to="/sors"
                data-cy="menu-sors"
              >
                {t("SORs")}
              </Button>
            </div>
            {isHelpImagesEnabled() && (
              <div style={{display: "flex", flexDirection: "column"}}>
                <Button
                  fullWidth
                  size="lg"
                  variant="subtle"
                  component={Link}
                  to="/media"
                  data-cy="menu-media"
                >
                  {t("Media")}
                </Button>
              </div>
            )}

          </Navbar.Section>
        </Navbar>
      }
      styles={(theme) => ({
        main: {
          backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.gray[0],
        },
      })}
    >
      <Rts>
        <Route
          path="/"
          element={<div>{t("Use the menu on the left to navigate")}</div>}
        />
        <Route
          path="/valuesets"
          element={<ValuesetListPage/>}
        />
        <Route
          path="/questionnaires"
          element={<QuestionnaireListPage/>}
        />
        <Route
          path="/sors"
          element={<SORListPage/>}
        />
        {isHelpImagesEnabled() && (
          <Route
            path="/media"
            element={<MediaListPage/>}
          />
        )}

        <Route
          path="/formbuilder"
          element={
            <React.Suspense
              fallback={
                <Loader
                  variant="dots"
                  size="xl"
                />
              }
            >
              <TreeContextProvider>
                <FormBuilder/>
              </TreeContextProvider>
            </React.Suspense>
          }
        />
      </Rts>
    </AppShell>
  );
}
