import { AxiosResponse } from "axios";
import { Bundle, BundleEntry, Media } from "../types/fhir";
import { apiClientService } from "./apiClientService";
import { getNextUrl } from "./paging";
import { IExtentionType } from "../types/IQuestionnareItemType";

const apiClient = apiClientService.axiosClient;

// CRUD operations for Media
//https://www.hl7.org/fhir/media.html

export enum MediaStatus {
  Unknown = 404,
  Deleted = 410,
  Active = 200,
}

export type MediaResponse = {
  data: Media | null;
  status: MediaStatus;
};

const getAll = async (): Promise<Media[] | undefined> => {
  let response = await apiClient.get<Bundle>("/fhir/Media");

  const medias = new Set<Media>();

  mapBundleToMedias(response.data)?.forEach((media) => {
    medias.add(media);
  });

  while (getNextUrl(response.data)) {
    const nextUrl = getNextUrl(response.data);
    if (!nextUrl) break;

    response = await apiClient.get<Bundle>(nextUrl);
    mapBundleToMedias(response.data)?.forEach((media) => {
      medias.add(media);
    });
  }
  return Array.from(medias);
};

const getAllForOverview = async (): Promise<Media[] | undefined> => {
  let response = await apiClient.get<Bundle>("/fhir/Media", {
    params: {
      _elements: "id, width, height, content, text",
      _sort: "created",
    },
  });

  const medias = new Set<Media>();

  mapBundleToMedias(response.data)?.forEach((media) => {
    medias.add(media);
  });

  while (getNextUrl(response.data)) {
    const nextUrl = getNextUrl(response.data);
    if (!nextUrl) break;

    response = await apiClient.get<Bundle>(nextUrl);
    mapBundleToMedias(response.data)?.forEach((media) => {
      medias.add(media);
    });
  }
  return Array.from(medias);
};

const getOneWithVersion = async (id: string, version: string): Promise<MediaResponse> => {
  const response = await apiClient.get<Media>(`/fhir/Media/${id}/_history/${version}`);
  if (response.status === 410) {
    return { data: null, status: MediaStatus.Deleted };
  }
  if (response.status === 404) {
    //Resource is not known
    return { data: null, status: MediaStatus.Unknown };
  }

  return { data: response.data, status: MediaStatus.Active };
};

const getOne = async (id: string): Promise<MediaResponse> => {
  //If there is a version on, call the correct method
  if (id.includes("/")) {
    const splittedVersion = id.split("/");
    return await getOneWithVersion(splittedVersion[0], splittedVersion[1]);
  }

  const response = await apiClient.get<Media>(`/fhir/Media/${id}`);
  if (response.status === 410) {
    return { data: null, status: MediaStatus.Deleted };
  }
  if (response.status === 404) {
    //Resource is not known
    return { data: null, status: MediaStatus.Unknown };
  }

  return { data: response.data, status: MediaStatus.Active };
};

const getByTitle = async (title: string): Promise<Media[] | undefined> => {
  const response = await apiClient.get<Bundle>(`/fhir/Media?title=${title}`);
  return mapBundleToMedias(response.data);
};

/**
 * @param media
 * Add a new media. If media with same hash already exists, it will return that media.
 * @returns 200 if existing media was returned or 201 if new media was created
 */
const addOne = async (media: Media): Promise<AxiosResponse<Media | null>> => {
  const sha1Hash = media?.extension?.find((ext) => ext.url === IExtentionType.sha1Hash)?.valueString;
  if (sha1Hash) {
    //Check if image already exists on server and return it
    const image = await getOneByHash(sha1Hash);
    if (image) {
      return { data: image, status: 200, statusText: "OK", headers: {}, config: {} };
    }
  }

  const response = await apiClient.post<Media>("/fhir/Media", media);
  return response;
};

const getOneByHash = async (hash: string): Promise<Media | undefined> => {
  const response = await apiClient.get<Bundle>(`/fhir/Media?_elements=extension`);
  const fromBundle = mapBundleToMedias(response.data);
  const foundImage = fromBundle?.find(
    (media) => media?.extension?.find((ext) => ext.url === IExtentionType.sha1Hash)?.valueString === hash
  );
  if (foundImage) {
    const fullImage = await getOne(foundImage.id ?? "");
    return fullImage.data ?? foundImage;
  }
  return undefined;
};

const updateOne = async (media: Media): Promise<number> => {
  const response = await apiClient.put<Media>(`/fhir/Media/${media.id}`, media);
  return response.status;
};

const removeOne = async (id: string): Promise<number> => {
  const response = await apiClient.delete<Media>(`/fhir/Media/${id}`);
  return response.status;
};

const mediaService = {
  getAll,
  getOne,
  getOneWithVersion,
  getOneByHash,
  getByTitle,
  addOne,
  updateOne,
  removeOne,
  getAllForOverview,
};

// Mappers

const mapBundleToMedias = (bundle: Bundle): Media[] | undefined => {
  return bundle?.entry?.map((entry: BundleEntry) => {
    return JSON.parse(JSON.stringify(entry.resource));
  });
};

export default mediaService;
