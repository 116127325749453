import "./index.css";

import * as serviceWorker from "./serviceWorker";

import App from "./App";
import React from "react";
import ReactDOM from "react-dom";
import { UserProvider } from "./contexts/UserContext";
import "./helpers/i18n";
import { keycloakService } from "./connection/keycloakService";
import { apiClientService } from "./connection/apiClientService";

const container = document.getElementById("root");

const renderApp = (): void => {
  ReactDOM.render(
    <React.StrictMode>
      <UserProvider>
        <App />
      </UserProvider>
    </React.StrictMode>,
    container
  );
};

const renderFailedLogin = (): void => {
  ReactDOM.render(
    <React.StrictMode>
      <div className={"block"}>
        <p style={{ textAlign: "center" }}>Der skete en fejl under login.</p>
        <button
          className={"block.center-text"}
          onClick={() => {
            keycloakService
              .manualLogin()
              .then(() => renderApp())
              .catch(() => renderFailedLogin());
          }}
        >
          Prøv igen
        </button>
      </div>
    </React.StrictMode>,
    container
  );
};

keycloakService.login(renderApp, renderFailedLogin);
apiClientService.configure();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
