import {
  removeDraftExtension,
  updateDraftExtension,
  updateItemAction,
  updateQuestionnaireMetadataAction,
} from "../store/treeStore/treeActions";
import { ActionType } from "../store/treeStore/treeStore";
import { Element, Extension, QuestionnaireItem } from "../types/fhir";
import { HyperlinkTarget } from "../types/hyperlinkTargetType";
import { IQuestionnaireMetadata, IQuestionnaireMetadataType } from "../types/IQuestionnaireMetadataType";
import { IExtentionType, IItemProperty, IValueSetSystem } from "../types/IQuestionnareItemType";
import createUUID from "./CreateUUID";

export const setItemExtension = (
  item: QuestionnaireItem,
  extensionValue: Extension,
  dispatch: (value: ActionType) => void
): void => {
  const extensionsToSet = (item.extension || []).filter((x: Extension) => x.url !== extensionValue.url);
  extensionsToSet.push(extensionValue);
  dispatch(updateItemAction(item.linkId, IItemProperty.extension, extensionsToSet));
};

export const removeItemExtension = (
  item: QuestionnaireItem,
  extensionUrl: string | string[],
  dispatch: (value: ActionType) => void
): void => {
  const extensionsToSet = Array.isArray(extensionUrl)
    ? (item.extension || []).filter((x: Extension) => extensionUrl.indexOf(x.url) === -1)
    : (item.extension || []).filter((x: Extension) => x.url !== extensionUrl);
  dispatch(updateItemAction(item.linkId, IItemProperty.extension, extensionsToSet));
};

export const setQuestionnaireExtension = (
  qMetadata: IQuestionnaireMetadata,
  extensionValue: Extension,
  dispatch: (value: ActionType) => void
): void => {
  const extensionsToSet = (qMetadata.extension || []).filter((x: Extension) => x.url !== extensionValue.url);
  extensionsToSet.push(extensionValue);
  dispatch(updateQuestionnaireMetadataAction(IQuestionnaireMetadataType.extension, extensionsToSet));
};

export const removeQuestionnaireExtension = (
  qMetadata: IQuestionnaireMetadata,
  extensionUrl: string,
  dispatch: (value: ActionType) => void
): void => {
  const extensionsToSet = (qMetadata.extension || []).filter((x: Extension) => x.url !== extensionUrl);
  dispatch(updateQuestionnaireMetadataAction(IQuestionnaireMetadataType.extension, extensionsToSet));
};

export const createOptionReferenceExtensions = [
  {
    url: IExtentionType.optionReference,
    valueReference: {
      reference: "",
      display: "",
      id: createUUID(),
    },
  },
  {
    url: IExtentionType.optionReference,
    valueReference: {
      reference: "",
      display: "",
      id: createUUID(),
    },
  },
];

export const createMarkdownExtension = (markdownValue: string): Element => {
  return {
    extension: [
      {
        url: IExtentionType.markdown,
        valueMarkdown: markdownValue,
      },
    ],
  };
};

export const hasExtension = (extensionParent: Element | undefined, extensionType: IExtentionType): boolean => {
  if (extensionParent && extensionParent.extension) {
    return extensionParent.extension.some((ext) => ext.url === extensionType);
  }
  return false;
};

export const createGuidanceActionExtension = (valueString = ""): Extension => ({
  url: IExtentionType.guidanceAction,
  valueString,
});

export const createGuidanceParameterExtension = (valueString = ""): Extension => ({
  url: IExtentionType.guidanceParam,
  valueString,
});

export const createCprIdentificationExtension = (): Extension => ({
  url: IExtentionType.regEx,
  valueString:
    "^(?<dayTmp>(0[1-9])|[1-2][0-9]|3[0-1])(?<monthTmp>((0[1-9])|1[0-2]))(?<yearTmp>([0-9]{2}))(-?)(?<newYearSeperator>(0|6))(?<lastNameFirstLetter>([a-zA-Z]))(?<firstNameFirstLetter>([a-zA-Z]))(?<tmpGenderIdentifier>([0-9]))$|^(?<day>(0[1-9])|[1-2][0-9]|3[0-1])(?<month>((0[1-9])|1[0-2]))(?<year>([0-9]{2})(?<dash>([-]))?(?<identifier>([0-9]{4})))$",
});

export const createValidationTextExtension = (valueString: string): Extension => ({
  url: IExtentionType.validationtext,
  valueString: valueString,
});

export const createHyperlinkTargetExtension = (codeValue = 2): Extension => ({
  url: IExtentionType.hyperlinkTarget,
  valueCoding: {
    system: IValueSetSystem.hyperlinkTargetValueset,
    code: `${codeValue}`,
  },
});

export interface CalculationVariable {
  name: string;
  id: string;
  variableName?: string;
  questionnaireTemplateGuid?: string;
  variableType?: "number" | "time" | undefined
  cprNumber?: string;
}

export const SetCalculationVariableSet = (
  value: CalculationVariable[],
  dispatch: (value: ActionType) => void
): void => {
  dispatch(updateDraftExtension(IExtentionType.calculationVariableSet, value));
};

export const RemoveCalculationVariableSet = (
  value: CalculationVariable[],
  dispatch: (value: ActionType) => void
): void => {
  dispatch(removeDraftExtension(IExtentionType.calculationVariableSet, value));
};

export interface ValidateItem {
  linkId: string;
}

export interface ValidationPair {
  validationType: ValidationType;
  validateItem: ValidateItem;
  id?: string;
}

export interface ValidationControl {
  itemUnderTest: ValidateItem;
  validations: ValidationPair[];
}

export enum ValidationType {
  min = "min",
  max = "max",
  equal = "equal",
  notEqual = "not_equal",
}

export const SetValidationArray = (value: ValidationControl[], dispatch: (value: ActionType) => void): void => {
  dispatch(updateDraftExtension(IExtentionType.validationArray, value));
};

export const GetValidationArray = (extensions: Extension[]): ValidationControl[] | undefined => {
  const validationArray = extensions.find((ext) => ext.url === IExtentionType.validationArray);
  if (validationArray && validationArray.valueString) {
    return JSON.parse(validationArray.valueString);
  }
};

export const GetCalculationVariableSet = (extensions: Extension[]): CalculationVariable[] | undefined => {
  const calculationVariableSetExtension = extensions.find((ext) => ext.url === IExtentionType.calculationVariableSet);
  if (calculationVariableSetExtension && calculationVariableSetExtension.valueString) {
    return JSON.parse(calculationVariableSetExtension.valueString);
  }
  return undefined;
};

export const GetCalculationExpression = (extensions: Extension[]): string | undefined => {
  const calculationExpressionExtension = extensions.find((ext) => ext.url === IExtentionType.calculationExpression);
  if (calculationExpressionExtension && calculationExpressionExtension.valueString) {
    return calculationExpressionExtension.valueString;
  }
  return undefined;
};

export const getHyperlinkTargetvalue = (extensions: Extension[]): HyperlinkTarget | undefined => {
  const hyperlinkExtension = extensions?.find((extension) => extension.url === IExtentionType.hyperlinkTarget);
  if (hyperlinkExtension) {
    const value = hyperlinkExtension.valueCoding?.code;
    if (value) return ~~value;
  }
  return undefined;
};
